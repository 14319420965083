@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  box-sizing: border-box;
}
:root {
  /* --basic-color: #07fb5a; */
  --basic-color: #e7a61a;
  --basic-white: #fff;
  --basic-grey: #616161;
  --basic-grey2: #bdbdbd;
  --light-grey: #d9d9d9;
}

textarea:focus,
input:focus {
  box-shadow: none !important;
}
a {
  text-decoration: none;
}
p {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
}
.admin_section {
  /* max-width: 1500px; */
  margin: 0 auto;
  position: relative;
  /* background-color: #000000;
  height: 100vh; */
}
