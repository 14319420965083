.auth-section-input {
    max-width: 1000px;
    margin: 0 auto !important;
    /* min-width: 500px; */
  }
  .auth-section-input.row {
    margin-right: 0;
    margin-left: 0;
  }
  .auth-section-input .row {
    margin-right: 0;
    margin-left: 0;
  }
  
  .auth-section-input .image-container {
    background: linear-gradient(180deg, var(--basic-color) , #000 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .auth-section-input label {
    font-size: 12px;
    color: #757575;
    font-family: "Argentum Sans", sans-serif;
  }
  .auth-section-input input::placeholder {
    font-size: 12px;
    color: #757575;
    font-family: "Argentum Sans", sans-serif;
  }
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
  }
  .input-group-append {
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
    color: #757575;
  }
  .input-group {
    position: relative;
  }
  .remember-forgot {
    font-family: "Argentum Sans", sans-serif;
    font-size: 12.8px;
    color: #757575;
  }
  .remember-forgot a {
    text-decoration: none;
  }
  .remember-forgot a:hover {
    transform: scale(1.01);
  }
  .reg-btn {
    cursor: pointer;
  }
  
  .horizontal-rule {
    color: #757575;
  }
  .horizontal-rule span {
    height: 1px;
    background-color: #757575;
    width: 100%;
  }
  .flash-msgs {
    font-size: 12px;
  }
  
  @media (max-width: 400px) {
    .social-sign-up span {
      font-size: 12px;
    }
  }