.admin_main-section {
    position: relative;
    width: auto;
    margin-left: 275px;
}
.admin_main-section {
    background-color: #e0e0e0;
    font-family: "Poppins", sans-serif;
}
.admin_main-section .admin-body {
    max-width: 1500px;
    margin: 0 auto;
}

.metrics-card {
    min-height: 150px;
}
.login {
    background-color: var(--basic-color);
}
.admin-body th {
    background-color: #9e9e9e !important;
    border-width: 2px !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}
.tasks-section label {
    color: #000;
}


@media screen and (max-width: 992px) {
    .admin_main-section {
        transition: margin-left 0.3s ease;
        margin-left: 0;
    }
    .admin_main-section .bars-btn {
        visibility: visible;
    }
}