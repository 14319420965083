.admin-aside {
    overflow-y: auto;
    left: 0;
    width: 276px;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 1;
    transition: left 0.3s ease;
}

.admin-aside.sidebar-opened {
    left: 0;
}

.admin-aside .close-aside-btn:hover {
    color: #e31f1fd5;
}
.admin-aside .close-aside-btn {
    position: absolute;
    cursor: pointer;
    display: none;
    left: 225px;
    top: 25px;
    color: #ec6e6e89;
}

.admin-aside::-webkit-scrollbar {
    width: 5px;
}
.admin-aside::-webkit-scrollbar-thumb {
    background-color: var(--basic-color);
    border-radius: 100px;
}
.admin-aside::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    border-radius: 100px;
}


.admin-aside .nav-link.active {
    color: var(--basic-color);
}
.admin-aside .nav-link span {
    font-family: 'Poppins', sans-serif;
}

@media (max-width: 992px) {
    .admin-aside {
        left: -276px;
        width: 276px;
        /* padding-left: 10px; */
    }
    .admin-aside .close-aside-btn {
        display: block;
    }
}
